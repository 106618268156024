import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import PurchaserFields from './PurchaserFields';

const PurchaserForm = ({ showPurchaserForm, initialValues, isExchange }) => {
  const { t } = useTranslation('payment');
  const { SHOW_PURCHASER_FORM_BILLING_TITLE: showBillingTitle } = useWhitelabelFeatures();
  return (
    <>
      <div className="checkout-header">
        <Text size="XL" weight="bold" className="checkout-header-title">
          {showBillingTitle ? t('purchaser_info_billing') : t('purchaser_info')}
        </Text>
      </div>
      <PurchaserFields
        isExchange={isExchange}
        purchaser={initialValues}
        showPurchaserForm={showPurchaserForm}
      />
    </>
  );
};

PurchaserForm.propTypes = {
  initialValues: PropTypes.object,
  showPurchaserForm: PropTypes.bool,
  isExchange: PropTypes.bool,
};

export default PurchaserForm;
