import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isSeat, isPetFriendlySeat } from 'utils/purchase/seats';
import { Box, Gap, Icon, MessageBox, Spacing, Text } from '@reservamos/elements';
import BadgeNew from 'ui/atoms/BadgeNew';
import 'shared-styles/purchase/BusDiagram';
import 'styles/components/purchase/BusDiagram';
import i18n from 'i18next';
import NewSeat from 'ui/atoms/NewSeat';
import withGrowthBookFeatures from 'components/GrowthBookProvider/withGrowthBookFeatures';
import PetBadge from '../../images/badges/pet.svg';
import AdjacentIcon from '../../ui/atoms/AdjacentIcon';

const propTypes = {
  layout: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          category: PropTypes.string.isRequired,
          number: PropTypes.string,
          occupied: PropTypes.bool,
        }),
      ),
    ),
  ),
  diagramType: PropTypes.string,
  selectedSeats: PropTypes.array,
  lockedSeats: PropTypes.array,
  changeSeat: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
  showPetFriendly: PropTypes.bool,
  petFriendlySoldOut: PropTypes.bool,
  isExchange: PropTypes.bool,
  id: PropTypes.string,
  isResultsLayout: PropTypes.bool,
  growthBookFeatures: PropTypes.object,
  switchDeck: PropTypes.func,
  selectedDeck: PropTypes.number,
  verticalSeats: PropTypes.bool,
};

/**
 * Represents a bus diagram component.
 */
class BusDiagram extends Component {
  constructor(props) {
    super(props);
    this.selectDeck = this.selectDeck.bind(this);
  }

  changeSeat(busSpace) {
    const { changeSeat, lockedSeats } = this.props;
    const { occupied, number } = busSpace;

    if (occupied && !lockedSeats.includes(number)) return;
    changeSeat(busSpace);
  }

  selectDeck({ selectedDeck }) {
    return () => {
      const { switchDeck } = this.props;
      switchDeck({ selectedDeck });
    };
  }

  renderBusSpace(deck, busSpace, rIndex, index) {
    const {
      lockedSeats,
      selectedSeats,
      diagramType,
      showPetFriendly,
      isExchange,
      isResultsLayout,
      verticalSeats,
    } = this.props;
    const isVerticalLayout = verticalSeats && !isResultsLayout;
    const { category, number, occupied } = busSpace;
    let itemClass = category.replace(/_/gi, '-');
    // TODO: Patch to remove pet-seat if showPetFriendly is false. This is a temporary solution. Issue: https://reservamossaas.atlassian.net/browse/ENT-809
    if (!showPetFriendly && isPetFriendlySeat(category)) {
      itemClass = 'seat';
    }

    const busSpaceClass = () => {
      const isInLastRow = deck.length === rIndex + 1;
      const isVanAisle = index === 5 && diagramType === 'van' && !isInLastRow;
      const isBusAisle = index === 3 && diagramType !== 'van';

      if ([0, 2, 4, 6].includes(index)) {
        return 'seats-layout-item';
      }
      if (isVanAisle || isBusAisle) {
        return 'seats-layout-aisle';
      }

      return 'seats-layout-item-middle';
    };
    const seatClass = () => {
      const seat = selectedSeats.find((seat) => seat.number === number);
      if (seat) {
        if (seat.isPickedAsAdjacent) {
          itemClass = 'adjacent-seat';
        }
        return `${itemClass}-selected`;
      }

      if (occupied) {
        return itemClass + (lockedSeats.includes(number) ? '-available' : '-occupied');
      }

      return `${itemClass}-available`;
    };

    const getSeatType = () => {
      const seat = selectedSeats.find((seat) => seat.number === number);

      if (seat) {
        if (seat.isPickedAsAdjacent) {
          return 'adjacent';
        }
        return 'selected';
      }

      if (occupied) {
        if (lockedSeats.includes(number)) {
          return 'available';
        }
        return 'occupied';
      }

      return 'available';
    };

    /**
     * Get the seat icon based on the category and showPetFriendly flag.
     * @returns {string} The seat icon.
     */

    let busSpaceType = <i className={itemClass} />;

    if (isSeat(category)) {
      busSpaceType = (
        <NewSeat
          number={number}
          type={getSeatType()}
          hasTv={category === 'seat_tv'}
          onClick={() => this.changeSeat(busSpace)}
          newDesign={isResultsLayout}
          showPetFriendly={showPetFriendly}
          category={category}
          seatClass={seatClass()}
          disabled={occupied}
          topTvOnDesktop={isVerticalLayout}
        />
      );
    }

    // TODO: Mover estas condiciones a un Factory, que con base en la categoría regrese la vista que se debe mostrar
    if (category === 'hallway_adjacent' && !isExchange) {
      busSpaceType = <AdjacentIcon isVertical={isVerticalLayout} />;
    }

    return (
      <div key={`bd-bs-${rIndex}-${index}`} className={busSpaceClass()}>
        {busSpaceType}
      </div>
    );
  }

  renderRow(deck, row, index) {
    return (
      <div key={`bd-row-${index}`} className="seats-layout-row">
        {row.map((busSpace, bsIndex) => {
          const renderedBusSpace = this.renderBusSpace(deck, busSpace, index, bsIndex);
          return renderedBusSpace;
        })}{' '}
      </div>
    );
  }

  renderDeck(deck, index, roundedSeatsAmount) {
    const {
      diagramType,
      disabled,
      petFriendlySoldOut,
      isResultsLayout,
      growthBookFeatures,
      verticalSeats,
    } = this.props;
    const { resultsSeats: resultsSeatsAb } = growthBookFeatures;

    const isVerticalLayout = verticalSeats && !isResultsLayout;
    const disabledClass = disabled ? 'vehicle-container-disabled' : '';
    const verticalLayout = isVerticalLayout ? 'vehicle-container-vertical' : '';
    const newLayout = isResultsLayout ? 'new-container' : '';
    const vehicleLayoutConfig = `${verticalLayout} ${newLayout} ${diagramType} ${disabledClass}`;
    const rowsCount = deck.length;
    const moreThanTwentyRows = rowsCount > 14;
    const largeSeatNumber = roundedSeatsAmount > 50;
    const additionalClass = moreThanTwentyRows ? 'hundred' : '';

    const petSoldOutMessage = petFriendlySoldOut && (
      <Box borderRadius="S" hasShadow paddingHorizontal="S" paddingVertical="S">
        <Spacing alignItems="center" size="S">
          <Icon type="Info" size="S" />
          <Text>{i18n.t('seats:pets_soldout')}</Text>
        </Spacing>
      </Box>
    );

    return (
      <Spacing vertical>
        {petSoldOutMessage}

        {largeSeatNumber && (
          <MessageBox borderColor="info">
            <Gap alignItems="center">
              <BadgeNew />
              <Text size="S">{i18n.t('seats:many_seats', { count: roundedSeatsAmount })}</Text>
            </Gap>
          </MessageBox>
        )}

        <Spacing vertical size="S">
          <div className={`vehicle-container ${vehicleLayoutConfig}`}>
            {resultsSeatsAb && isResultsLayout && (
              <div className="vehicle-front">
                <div className="wheel-front">
                  <i className="wheel-front-icon" />
                </div>
              </div>
            )}

            {!isResultsLayout && <i className={`${diagramType}-front`} />}

            <div key={`bd-deck-${index}`} className={`seats-layout ${additionalClass}`}>
              {deck.map((row, rIndex) => this.renderRow(deck, row, rIndex))}
            </div>

            {!isResultsLayout && <i className={`${diagramType}-back`} />}
          </div>
        </Spacing>
      </Spacing>
    );
  }

  renderDiagram(layout) {
    const firstDeck = layout[0];
    const secondDeck = layout[1];
    const { showPetFriendly, id, selectedDeck, growthBookFeatures } = this.props;
    const { resultsSeats: resultsSeatsAb } = growthBookFeatures;
    const flattenLayout = layout.flat(2).filter((element) => isSeat(element.category));
    const roundedSeatsAmount = Math.floor(flattenLayout.length / 10) * 10;
    if (secondDeck) {
      return (
        <div className="tabs">
          {!resultsSeatsAb && (
            <div className="tabs-top">
              <ul className="tabs-list">
                <li className="tab tabs-item tabs-2" onClick={this.selectDeck({ selectedDeck: 1 })}>
                  <a
                    href={`#secondDeck-${id}`}
                    className={`tabs-item-content ${selectedDeck === 1 ? 'active' : ''}`}
                  >
                    {i18n.t('seats:2nd_floor')}
                  </a>
                </li>
                <li className="tab tabs-item tabs-2" onClick={this.selectDeck({ selectedDeck: 0 })}>
                  <a
                    href={`#firstDeck-${id}`}
                    className={`tabs-item-content ${selectedDeck === 0 ? 'active' : ''}`}
                  >
                    <Gap alignItems="center">
                      {i18n.t('seats:1st_floor')}
                      {showPetFriendly && <Icon type={PetBadge} size="M" mobileSize="S" />}
                    </Gap>
                  </a>
                </li>
              </ul>
            </div>
          )}

          <div>
            {this.renderDeck(selectedDeck === 0 ? firstDeck : secondDeck, 0, roundedSeatsAmount)}
          </div>
        </div>
      );
    }

    return this.renderDeck(firstDeck, 0, roundedSeatsAmount);
  }

  render() {
    const { layout } = this.props;

    return <div className="animated fade-in">{this.renderDiagram(layout)}</div>;
  }
}

BusDiagram.propTypes = propTypes;
BusDiagram.defaultProps = {
  layout: [
    [
      [
        { category: 'seat_tv', number: '1', occupied: false },
        { category: 'hallway' },
        { category: 'seat', number: '2', occupied: false },
        { category: 'hallway' },
        { category: 'seat', number: '3', occupied: false },
        { category: 'hallway' },
        { category: 'seat', number: '4', occupied: false },
      ],
      [
        { category: 'seat', number: '5', occupied: false },
        { category: 'hallway' },
        { category: 'seat', number: '6', occupied: false },
        { category: 'hallway' },
        { category: 'seat', number: '7', occupied: false },
        { category: 'hallway' },
        { category: 'seat', number: '8', occupied: false },
      ],
      [
        { category: 'seat', number: '9', occupied: false },
        { category: 'hallway' },
        { category: 'seat', number: '10', occupied: false },
        { category: 'hallway' },
        { category: 'seat_tv', number: '11', occupied: false },
        { category: 'hallway' },
        { category: 'seat', number: '12', occupied: false },
      ],
      [
        { category: 'seat_tv', number: '13', occupied: false },
        { category: 'hallway' },
        { category: 'seat', number: '14', occupied: false },
        { category: 'hallway' },
        { category: 'seat', number: '15', occupied: false },
        { category: 'hallway' },
        { category: 'seat', number: '16', occupied: false },
      ],
      [
        { category: 'seat', number: '17', occupied: false },
        { category: 'hallway' },
        { category: 'seat', number: '18', occupied: false },
        { category: 'hallway' },
        { category: 'seat', number: '19', occupied: false },
        { category: 'hallway' },
        { category: 'seat', number: '20', occupied: false },
      ],
      [
        { category: 'seat', number: '21', occupied: false },
        { category: 'hallway' },
        { category: 'seat', number: '22', occupied: false },
        { category: 'hallway' },
        { category: 'seat_tv', number: '23', occupied: false },
        { category: 'hallway' },
        { category: 'seat', number: '24', occupied: false },
      ],
      [
        { category: 'seat', number: '25', occupied: false },
        { category: 'hallway' },
        { category: 'seat', number: '26', occupied: false },
        { category: 'hallway' },
        { category: 'seat', number: '27', occupied: false },
        { category: 'hallway' },
        { category: 'seat', number: '28', occupied: false },
      ],
      [
        { category: 'seat', number: '29', occupied: false },
        { category: 'hallway' },
        { category: 'hallway' },
        { category: 'hallway' },
        { category: 'hallway' },
        { category: 'hallway' },
        { category: 'seat', number: '30', occupied: false },
      ],
    ],
  ],
  diagramType: 'bus',
  selectedSeats: [],
  lockedSeats: [],
  changeSeat: null,
};

const mapStateToProps = (state) => ({
  copies: state.whitelabelConfig.copies,
});

export default connect(mapStateToProps)(withGrowthBookFeatures(BusDiagram, 'results_seats'));
