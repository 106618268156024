import React from 'react';
import PropTypes from 'prop-types';
import { When } from 'react-if';
import { Spacing, Text } from '@reservamos/elements';
import { useSelector } from 'react-redux';
import prepareTicketsPricing from 'utils/purchase/prepareTicketsPricing';
import { useTranslation } from 'react-i18next';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import { getCurrencyPrefix, getCurrencySuffix } from 'utils/currency';
import PricingRow from '../../ui/atoms/PricingRow';
import wayIsOpenTicket from '../../utils/wayIsOpenTicket';
import discountGFA from '../../images/brands-unique/gfa/early-discount.png';
import discountETN from '../../images/brands-unique/etn/discountETN.png';

const earlyDiscountIcons = {
  etn: discountETN,
  gfa: discountGFA,
  default: 'emojiSparkles',
};

const propTypes = {
  reservation: PropTypes.shape({
    fragments: PropTypes.array.isRequired,
    pricing: PropTypes.shape({
      amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      providerDiscount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      providerDiscountPercent: PropTypes.number,
      providerDiscountReason: PropTypes.string,
      taxes: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      serviceFees: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      breakdown: PropTypes.shape(),
    }).isRequired,
    transportType: PropTypes.string.isRequired,
  }).isRequired,
  passengers: PropTypes.array.isRequired,
  passengerCount: PropTypes.number.isRequired,
  totalLabel: PropTypes.string.isRequired,
  displayPricingBeforeCheckout: PropTypes.bool,
  passengersCountBeforeCheckout: PropTypes.number,
  exchangedTotal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pricingBeforeCheckout: PropTypes.shape({
    amount: PropTypes.number,
    discount: PropTypes.number,
    total: PropTypes.number,
    luggage: PropTypes.number,
    taxes: PropTypes.number,
    networks: PropTypes.number,
    insurance: PropTypes.number,
  }),
};

// eslint-disable-next-line react/prop-types
const RowComponent = ({ label, discountLabel, price, hasDiscount, isMain, icon, primary }) => {
  return (
    <PricingRow
      label={label}
      discountLabel={discountLabel}
      isMain={isMain}
      hasDiscount={hasDiscount}
      price={price}
      sign={getCurrencyPrefix()}
      currency={getCurrencySuffix()}
      primary={primary}
      icon={icon}
    />
  );
};

const PurchasePricingTrip = ({
  reservation,
  passengers,
  passengerCount,
  totalLabel,
  displayPricingBeforeCheckout,
  passengersCountBeforeCheckout,
  pricingBeforeCheckout,
  exchangedTotal,
}) => {
  const { features, env } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation('purchase');
  const { brand } = useWhitelabelEnvs();
  const breakdownValuesThatCanBeDisplayedAtCero =
    env.theme.pricing?.breakdownValuesThatCanBeDisplayedAtCero || [];

  const { transportType, fragments, pricing } = reservation;
  const {
    providerDiscountReason,
    providerDiscountPercent,
    providerDiscount,
    taxes,
    serviceFees,
    amount,
    total,
    breakdown,
  } = pricing;
  const {
    equipaje: luggage,
    impuestos: taxesFromBreakdown,
    redes: networks,
    seguros: insurance,
    toll,
    boardingTax,
    managementCosts,
  } = breakdown || {
    equipaje: 0,
    impuestos: 0,
    redes: 0,
    seguros: 0,
    toll: 0,
    boardingTax: 0,
    managementCosts: 0,
  };
  const taxesToRender = taxes > 0 ? taxes : taxesFromBreakdown;

  const pricingToRender = displayPricingBeforeCheckout
    ? {
        ...pricingBeforeCheckout,
        passengerCount: passengersCountBeforeCheckout,
      }
    : {
        discount: providerDiscount,
        amount,
        total,
        passengerCount,
        luggage,
        taxes: taxesToRender,
        networks,
        insurance,
        serviceFees,
        toll,
        boardingTax,
        managementCosts,
      };

  const ticketsPassengersCount = pricingToRender.passengerCount;
  const ticketsAmount = pricingToRender.amount;
  const tickets = prepareTicketsPricing(
    transportType,
    fragments,
    passengers,
    ticketsPassengersCount,
    ticketsAmount,
    displayPricingBeforeCheckout,
  );

  const hasEarlyDiscount = providerDiscountReason === 'compra anticipada';
  const isOpenTicket = wayIsOpenTicket(reservation);
  const showPriceOutTaxes = features.SHOW_TICKET_PRICE_WITHOUT_TAX;

  return (
    <div className="section-content">
      <Spacing vertical size="S">
        {tickets.map((item) => {
          let categoryText = `${item.count} ${t(`passengers:type.${item.category}`, {
            context: brand,
          })}`;

          const toll = (item.toll ?? pricingToRender.toll) * item.count;
          const boardingTax = (item.boardingTax ?? pricingToRender.boardingTax) * item.count;
          const managementCosts =
            (item.managementCosts ?? pricingToRender.managementCosts) * item.count;
          const priceOutTaxes = item.total - toll - boardingTax - managementCosts;
          const price = showPriceOutTaxes ? priceOutTaxes : item.total;

          if (isOpenTicket) categoryText = `${categoryText} - ${t('general:open_ticket')}`;
          return <RowComponent key={item.category} label={categoryText} price={price} />;
        })}

        <When condition={providerDiscount > 0 && hasEarlyDiscount}>
          <RowComponent
            label={t('trips:early_discount')}
            price={providerDiscount}
            hasDiscount
            primary
            icon={earlyDiscountIcons[env.brand] ?? earlyDiscountIcons.default}
          />
        </When>
        <When condition={providerDiscount > 0 && !hasEarlyDiscount}>
          {features.PURCHASE_PRICING_DETAILED_DISCOUNT ? (
            <RowComponent
              label={t('purchase:label.your_discount_savings', {
                reason: providerDiscountReason,
              })}
              discountLabel={t('purchase:label.maximum_discount_percentage', {
                percent: providerDiscountPercent,
              })}
              price={pricingToRender.discount}
              hasDiscount
            />
          ) : (
            <Text color="grayMedium" size="XS">
              {t('label.discount', { context: env.brand })}
            </Text>
          )}
        </When>

        <When
          condition={
            pricingToRender.luggage > 0 ||
            breakdownValuesThatCanBeDisplayedAtCero.includes('luggage')
          }
        >
          <RowComponent label={t('luggage')} price={pricingToRender.luggage} />
        </When>

        <When
          condition={
            pricingToRender.toll > 0 || breakdownValuesThatCanBeDisplayedAtCero.includes('toll')
          }
        >
          <RowComponent label={t('toll')} price={pricingToRender.toll} />
        </When>

        <When
          condition={
            pricingToRender.boardingTax > 0 ||
            breakdownValuesThatCanBeDisplayedAtCero.includes('boardingTax')
          }
        >
          <RowComponent label={t('boardingTax')} price={pricingToRender.boardingTax} />
        </When>

        <When
          condition={
            pricingToRender.networks > 0 ||
            breakdownValuesThatCanBeDisplayedAtCero.includes('networks')
          }
        >
          <RowComponent label={t('networks')} price={pricingToRender.networks} />
        </When>

        <When
          condition={
            pricingToRender.insurance > 0 ||
            breakdownValuesThatCanBeDisplayedAtCero.includes('insurance')
          }
        >
          <RowComponent label={t('insurance')} price={pricingToRender.insurance} />
        </When>

        <When
          condition={
            pricingToRender.taxes > 0 || breakdownValuesThatCanBeDisplayedAtCero.includes('taxes')
          }
        >
          <RowComponent
            label={t('extra_price', { context: env.brand })}
            price={pricingToRender.taxes}
          />
        </When>

        <When
          condition={
            pricingToRender.serviceFees > 0 ||
            breakdownValuesThatCanBeDisplayedAtCero.includes('serviceFees')
          }
        >
          <RowComponent label={t('label.service_charge')} price={pricingToRender.serviceFees} />
        </When>

        <When
          condition={
            pricingToRender.managementCosts > 0 ||
            breakdownValuesThatCanBeDisplayedAtCero.includes('managementCosts')
          }
        >
          <RowComponent label={t('managementCosts')} price={pricingToRender.managementCosts} />
        </When>

        <RowComponent label={t(`label.${totalLabel}`)} price={pricingToRender.total} isMain />

        <When condition={exchangedTotal > 0}>
          <RowComponent label={t('previousTicketsTotal')} price={exchangedTotal} isMain />
        </When>
      </Spacing>
    </div>
  );
};

PurchasePricingTrip.propTypes = propTypes;

export default PurchasePricingTrip;
