import React from 'react';
import PropTypes from 'prop-types';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import TimeLineItem from './TimeLineItem';
import makeKey from './makeKey';
import 'styles/components/TripItinerary/ItineraryDetails';

const propTypes = {
  route: PropTypes.object,
  brand: PropTypes.string,
  lineDetails: PropTypes.object,
};

/**
 * Component used as part of trip itinerary.
 * @param {Object} props - Object props
 * @param {object} props.route - Indicates full route data.
 * @param {object[]} props.route.route - Contains a summary depart and end trip data .
 * @param {object} props.route.originDetails - Indicates trip's origin data.
 * @param {object} props.route.destinationDetails - Indicates trip's destination data.
 * @param {object} props.lineDetails - Object containing provider's line detail.
 * @returns {JSX.Element} A React component that renders trip's timeline .
 */
const TimeLine = ({ route: { route, originDetails, destinationDetails }, lineDetails }) => {
  const { brand } = useWhitelabelEnvs();
  const finalRoute =
    brand !== 'brasilia'
      ? route
      : route
          .map((timeSegment) => {
            if (timeSegment.type === 'stop') {
              return [
                {
                  type: 'end',
                  time: timeSegment.time[0].time,
                  title: timeSegment.title,
                  description: `Llega a: ${timeSegment.title}`,
                },
                {
                  type: 'departure',
                  time: timeSegment.time[1].time,
                  title: timeSegment.title,
                  description: `Sale de: ${timeSegment.title}`,
                },
              ];
            }
            return timeSegment;
          })
          .flat();

  let index = 1;
  const timeLineItems = finalRoute?.map((timeSegment) => {
    const key = makeKey(timeSegment);
    if (timeSegment.type === 'departure' && brand === 'brasilia') {
      timeSegment.index = index;
      index += 1;
    }

    /**
     * Brasilia is a particular case, we avoid to remove time segment on it
     * because they show different "Ruta" where end of first one could be same as start of second
     * and we need to show both times.
     */
    if (brand !== 'brasilia') {
      if (timeSegment.type === 'stop') {
        const isSameTime = timeSegment.time[0].time === timeSegment.time[1].time;
        if (isSameTime) {
          delete timeSegment.time[0];
        }
      }
    }

    return (
      <TimeLineItem
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...timeSegment}
        key={key}
        componentkey={key}
        brand={brand}
        originDetails={originDetails}
        destinationDetails={destinationDetails}
        lineDetails={lineDetails}
      />
    );
  });
  return <div>{timeLineItems}</div>;
};

TimeLine.propTypes = propTypes;

export default TimeLine;
